import React from 'react'

import HomeSlider from '../components/home-slider'
import TopCollection from '../components/top-collection'
import { graphql } from 'gatsby'
import CollectionBanner from '../components/collection-bannner'
import SEO from '../components/seo'
import Layout from '../layouts'

const Index = ({data}) => (
    <Layout>
        <SEO title="Home"/>
        <HomeSlider />
        <CollectionBanner />

        {data.shopifyCollection !== null && data.shopifyCollection.products.length > 0 && (
            <TopCollection products={data.shopifyCollection.products} />
        )}
    </Layout>
)

export const query = graphql `
    query homeQuery {
        shopifyCollection(id: {eq: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NzY3NjI0MDkzOA=="}) {
                products {
                    shopifyId
                    handle
                    title
                    description
                    variants {
                        shopifyId
                        title
                        availableForSale
                        priceV2 {
                                amount
                                currencyCode
                            }
                        compareAtPriceV2 {
                                amount
                                currencyCode
                            }
                        selectedOptions {
                                name
                                value
                            }
                        image {
                            localFile {
                                childImageSharp {
                                    fluid (maxWidth: 600, maxHeight: 600) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                }
        }
    }
`

export default Index