import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

const BannerItem = ({item}) => (
    <div className="col-md-6">
        <Link to={item.url}>
            <div className="collection-banner p-right text-center">
                {item.image !== null && (
                    <Img fluid={item.image.childImageSharp.fluid} className="img-fluid"/>
                )}
                {item.isVisible && (
                    <div className="contain-banner">
                        <div>
                            <h4>{item.title}</h4>
                        </div>
                    </div>
                )}
            </div>
        </Link>
    </div>
)

export default BannerItem