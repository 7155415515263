import React, { Component } from 'react';
import Slider from 'react-slick';
import ProductListItem from '../collection-settings/product-list-item';

// import {Product4, Product5} from '../../../services/script'
// import {addToCart, addToWishlist, addToCompare} from "../../../actions/index";
// import ProductItem from '../common/product-item';

class TopCollection extends Component {

    render (){

        const {products} = this.props;

        var properties = {
            infinite: true,
            // speed: 300,
            slidesToShow: 6,
            slidesToScroll: 2,
            autoplay: true,
            // autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow:2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
        // if(type === 'kids'){
        //     properties = Product5
        // }else{
        //     properties = Product4
        // }

        return (
            <div>
                {/*Paragraph*/}
                <div className="title1  section-t-space">
                    <h4>special offer</h4>
                    <h2 className="title-inner1">top collection</h2>
                </div>
                {/*Paragraph End*/}
                <section className="section-b-space p-t-0">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Slider {...properties} className="product-4 product-m no-arrow">
                                    { products.map((product, index ) =>
                                        <div key={index}>
                                            <ProductListItem product={product} key={index}/>
                                        </div>)
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default TopCollection;