import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import Img from 'gatsby-image'

const HomeSlider = () => (
    <StaticQuery
        query={graphql`
          {
            file(base: {eq: "home-page.md"}) {
              childMarkdownRemark {
                frontmatter {
                  home_slider {
                    title
                    subtitle
                    url
                    isVisible
                    image {
                      childImageSharp {
                        fluid (maxWidth: 1400){
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }        
        `}
        render={({file}) => (
            <section className="p-0">   
                <Slider className="home-slider">
                    {file.childMarkdownRemark.frontmatter.home_slider.map((slide,key) => (
                        <div key={key} 
                         >
                            <div className="home text-center" style={{ position: `relative` }}>
                              <Img 
                              fluid={slide.image.childImageSharp.fluid} 
                              style={{width: '100%', height: '100%'}}
                              />
                              {slide.isVisible && (
                                <div style={{position: 'absolute', top: 0,width: '100%', height: '100%' }}>
                                  <div  className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div>
                                                    {slide.subtitle && (<h4>{slide.subtitle}</h4>)}
                                                    {slide.title && (<h1>{slide.title}</h1>)}
                                                    {slide.url && (<Link to={slide.url} className="btn btn-solid">shop now</Link>)}
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              )}
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>
        )}
    />
)

export default HomeSlider