import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import BannerItem from './banner-item'

const CollectionBanner = () => (

    <StaticQuery
        query={graphql`
            {
                file(base: {eq: "home-page.md"}) {
                  childMarkdownRemark {
                    frontmatter {
                      collectionsBanner {
                        isVisible
                        collection1 {
                          isVisible
                          title
                          url
                          image {
                            childImageSharp {
                                fluid(maxWidth: 672) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                          }
                        }
                        collection2 {
                          isVisible
                          title
                          url
                          image {
                            childImageSharp {
                                fluid(maxHeight: 310) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
        render={({file}) => {
            const {isVisible, collection1, collection2} = file.childMarkdownRemark.frontmatter.collectionsBanner
            return (isVisible && (<section className="pb-0">
            <div className="container">
                <div className="row partition2">
                    <BannerItem item={collection1}/>
                    <BannerItem item={collection2}/>    
                </div>
            </div>
        </section>))}}    
    />
)

export default CollectionBanner